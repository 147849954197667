.search_form.hide {
    display: none;
}


/* The checkbox_container */

.checkbox_container {
    display: block;
    position: relative;
    margin: 12px;
    margin-left: 3%;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    /* user-select: none; */
    align-self: start;
}


/* Hide the browser's default checkbox */

.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    top: 0;
    background-color: #fff;
    border: 1px solid #2BB801;
    box-shadow: none;
}


/* On mouse-over, add a grey background color */

.checkbox_container:hover input~.checkmark {
    background-color: #2BB80181;
}


/* When the checkbox is checked, add a blue background */

.checkbox_container input:checked~.checkmark {
    background-color: #2BB801;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.checkbox_container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.checkbox_container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}





.index_search{
    display: flex;
    max-width: 100%;
    width: max-content;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0;
}
.index_search_subjects{
    width: 300px;
    padding: 7px;
    background: white;
    border-radius: 7px 7px 0 0;
}
.index_search_button_green{
    padding: 12px 30px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2BB801;
    color: #fff;
    text-decoration: none;
    text-align: center;
    transition: all .1s linear;
    font-weight: 700;
    font-size: 18px;
    border-radius: 0 0 7px 7px;
}
.index_search_button_green:hover {
    background-color: #57c337;
}
.index_search_button_green:active {
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 80%);
}



@media (min-width: 460px) {
    .index_search{
        flex-direction: row;
        align-items: center;
    }
    .index_search_subjects{ 
        border-radius: 7px 0 0 7px;
    }
    .index_search_button_green{
        border-radius: 0 7px 7px 0;
    }
}