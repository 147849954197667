.mobile {
    display: block;
}

.desctop {
    display: none;
}

.search_tutors {
    padding-top: 50px;
    background-color: #f3f3f3;
}

.search_tutors_title {
    font-size: 16px;
    text-transform: uppercase !important;
}

.search_tutors_wrapper {
    display: flex;
    flex-direction: column;
}

.search_tutors_wrapper_form {
    background-color: #fff;
    padding: 0 0 3% 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1180px;
    width: 100%;
    width: calc(100% - 20px);
}

.search_tutors_wrapper_form h2 {
    width: 100%;
    color: #fff;
    background: #2BB801;
    margin: 0;
    text-align: center;
}

.search_tutors_wrapper_form p {
    width: 94%;
    padding: 0;
    margin: 16px 0 2px;
}

.search_tutors_wrapper_form select {
    width: auto;
    padding: 6px;
    font-size: 20px;
    border-bottom: 1px solid green;
    display: block;
    width: 94%;
    position: relative;
    border-radius: 0px;
}

.search_tutors_wrapper_result {
    margin: 15px auto 0;
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.tutors-count {
    margin: 0 auto 15px;
}

.tutor_card {
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
    width: calc(100% - 30px);
}

.profile_card {
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
    max-width: calc(100vw - 30px);
}
.profile_card.blocked_tutor, .tutor_card.blocked_tutor {
    background-color: #cfcfcf;
}

.tutor_card h2,
.profile_card h2,
.profile_card .title
 {
    margin: 0;
    display: inline-block;
    white-space: pre-wrap;
}

.tutor_card h2 .link_users_chat::after,
.profile_card h2 .link_users_chat::after {
    top: 0;
}

.profile_card h3,
.profile_card p {
    margin: 0.5rem 0;
}

.tutor_card a,
.profile_card a {
    color: inherit;
    text-decoration: inherit;
}

.tutor_card.price_hide {
    display: none;
}

.info-block {
    display: flex;
    flex-direction: column;
}

.profile_info-block {
    display: flex;
    flex-direction: column;
}

.tutor_card_header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.profile_card_header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}

.shield::after {
    content: '';
    display: inline-block;
    background: url(../images/verified_user_black_24dp.svg) center no-repeat;
    width: 20px;
    height: 20px;
    background-size: 20px;
    margin: 0 5px;
}

.tutor_card_header>div {
    position: relative;
    flex-grow: 2;
}

.profile_card_header>div {
    position: relative;
    -webkit-flex-grow: 2;
    flex-grow: 2;
}

.tutor_card_header img,
.profile_card_header img {
    display: block;
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 50%;
    /* border: 3px solid white; */
    /* box-shadow: 1px 1px 5px -1px black; */
    /* 
    SET `photo`='/images/student.svg' WHERE `photo`='../images/avatar_student.png'
    ../images/avatar_area_box.png
    /images/avatar_area_box.png
    /images/student.svg
    /images/tutor.svg
    */
    margin: 0 15px 15px 0;
}

.tutor_card_header .avatar_big,
.profile_card_header .avatar_big,
.profile-field_flex .avatar_big,
.article .avatar_big {
    display: block;
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 50%;
    /* border: 3px solid white; */
    /* box-shadow: 1px 1px 5px -1px black; */
    margin: 0 15px 15px 0;
    background-size: cover;
    background-position: center;
}

.user-table-column {
    padding-right: 0;
    display: flex;
    height: 38px;
    /* width: 65%; */
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-top: 5px;
    align-items: center;
    padding-left: 26%;
}
.mobile > .user-table-column {
    padding-right: 7px;
    padding-left: 7px;
    width: calc( 100% - 14px );
}

.user-table-column.button:hover {
    background-color: #d5ebd4;
    cursor: pointer;
}

.check-mark:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 19px;
    background: url(../images/done_black_24dp.svg);
    background-size: 26px 19px;
    margin-left: 3px;
}

.button_green:active {
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 80%);
}

.button_green:hover {
    background-color: #57c337;
    box-shadow: 0 1px 2px rgb(0 0 0 / 80%);
}

.user-table-column.button_green {
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #2BB801;
    cursor: pointer;
}

.user-table-column.bgcolor_red {
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: red;
    cursor: pointer;
}

.star-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-image: url(../images/star_rate_black_24dp.svg);
    background-size: 20px;
    vertical-align: text-top;
}

.online-available {
    color: #2BB801;
    font-weight: 400;
    position: relative;
    line-height: 1;
    width: max-content;
}

.online-available::after {
    content: '';
    display: block;
    background: url(../images/public_black_24dp.svg) center no-repeat;
    width: 15px;
    height: 15px;
    background-size: 15px;
    position: absolute;
    right: -20px;
    top: 1px;
}

.tutor_card h3 {
    display: block;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 8px;
    margin-bottom: 12px;
    margin-top: 5px;
}

.p_lessons {
    margin: 0;
    margin-bottom: 3px;
    /* line-height: 1;
    display: flex;
    flex-wrap: wrap; */
    font-size: 18px;
    line-height: 1.2;
}

.new_link_users_attr {
    display: inline;
    /* padding: 4px 8px;
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 18px;
    text-transform: none;
    border-radius: 5px;
    background-color: #f3f3f3; */
}

.new_link_users_attr.check-mark {
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}

.link_users_attr {
    display: inline-block;
    padding: 4px 8px;
    margin: 0 7px;
    font-size: 16px;
    text-transform: none;
    border-radius: 5px;
    background-color: #2BB801;
    cursor: pointer;
}

.link_users_chat {
    position: relative;
    width: 26px;
    height: 20px;
    display: inline-block;
    margin-left: 7px;
}

.link_users_chat::after {
    content: '';
    display: block;
    background: url(../images/chat.svg) center no-repeat;
    width: 26px;
    height: 20px;
    background-size: 26px;
    position: absolute;
    /* right: -35px; */
    top: -14px;
    opacity: 0.7;
}

.link_users_chat:hover:after {
    opacity: 1;
}

.new_link_users_attr.tab {
    cursor: pointer;
    padding: 3px;
}

.new_link_users_attr.tab:hover {
    background-color: #d5ebd4;
}

.new_link_users_attr.tab.active {
    color: white;
    background-color: #2BB801;
}

.tab_content {
    display: none;
}

.tab_content.active {
    display: block;
}

.user_info {
    margin: 0;
    margin-bottom: 3px;
    font-size: 18px;
    line-height: 1.2;
}

.description {
    font-size: 16px;
    margin: 8px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.description.open{
    display: block;
}
.description span{
    pointer-events: none;
    user-select: none;
}


.green_text {
    text-align: center;
    color: #2BB801;
    font-size: 14px;
    margin-top: 3px;
}

.review_block {
    margin: 7px 0;
    padding: 14px;
    border-radius: 7px;
    box-shadow: 0 1px 5px -2px black;
}

@media (min-width: 1024px) {
    .mobile {
        display: none;
    }

    .desctop {
        display: block;
    }

    .tutor_card {
        display: flex;
        flex-direction: row;
    }

    .tutor_card_header {
        flex-direction: column;
    }

    .tutor_card_header h2 {
        margin: 0;
        font-size: 18px;
        text-align: center;
    }

    .info-block:nth-child(2) {
        flex: 1;
        margin: 0 12px 0 0;
        padding: 0 12px;
        border-right: 1px solid #eaeaea;
    }

    .tutor_card h3 {
        border-bottom: 0px solid #eaeaea;
        text-align: center;
    }

    .user-table-column {
        width: auto;
        margin-top: 0;
        margin-bottom: 8px;
    }

    .online-available {
        margin: 8px 0;
    }
}

.search_tutors_wrapper_form_block {
    width: 100%;
    max-width: 393px;
}

.breadcrumbs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 12px;
    margin: 7px 0;
    padding: 0;
}

.breadcrumbs li {margin: 2px;}
.breadcrumbs li::after {
    content: ">";
    margin: 2px;
}.breadcrumbs li:last-child:after {
    display: none;
}
.breadcrumbs a{
    color: #464c48;
    text-decoration: none;
}

.breadcrumbs a:hover{
    color: #2ab802;
    text-decoration: underline;
}
a.new_link_users_attr.check-mark:hover {
    border-bottom: 1px solid #2ab802;
}