.search_form {
    width: 94%;
    padding: 0;
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    color: #464C48;
}

.search_form span {
    width: auto;
    padding: 7px;
    /* font-size: 22px; */
    border-bottom: 1px solid green;
    display: block;
}

.search_form span::after {
    content: '';
    display: block;
    background: url(./images/arrow_down.svg) center no-repeat;
    width: 15px;
    height: 15px;
    background-size: 15px;
    position: absolute;
    right: 5px;
    top: 12px;
    opacity: 0.5;
    /* filter: drop-shadow(0px 1px 1px black); */
}

.search_form:hover span::after {
    opacity: 0.9;
}

.search_form span.active {
    background-color: white;
    border-radius: 4px 4px 0 0;
    z-index: 51;
    position: relative;
}

.search_form .select_search {
    background-color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 0px 6px -1px black;
    position: absolute;
    min-width: 100%;
    z-index: 50;
}

.search_form .select_search::before {
    content: '';
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    z-index: 50;
    position: absolute;
    width: 100%;
    height: 36px;
    display: block;
    top: -37px;
    border-bottom: 1px solid green;
    pointer-events: none;
}

.search_form .select_search_close {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
}

.search_form .select_search_close.show,
.search_form .select_search.show {
    display: block;
}

.search_form .select_search_close.hide,
.search_form .select_search.hide {
    display: none;
}

.search_form .select_search input {
    border: 1px solid #aaa;
    padding: 4px;
    width: -webkit-fill-available;
    box-sizing: border-box;
    margin: 4px;
    font-size: 22px;
    background: #fff;
    border-radius: 8px;
    color: #353535;
    position: relative;
    z-index: 2;
}

.search_form ul {
    max-height: 35vh;
    overflow: overlay;
    margin: 0;
    padding: 0;
}

.search_form ul li {
    margin: 0;
    padding: 4px 7px;
}

.search_form ul li:hover,
.search_form ul li.selected {
    background-color: #2BB801;
    color: white;
}

li.checkbox::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: solid #2BB801 1px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: inset 1px 1px 1px rgb(0 0 0 / 30%);
    margin-right: 7px;
}

.search_form ul li.checkbox:hover {
    background-color: transparent;
    color: #353535;
}

li.checkbox:hover::before {
    background-color: #2BB80170;
}

li.checkbox.checked::before {
    border: solid #2BB801 1px;
    background-color: #2BB801;
}