.main_wrapper {
    background-color: #fff;
    margin: 0;
    font-size: 24px;
    line-height: 1.3;
    /* width: calc(100vw - 50px); */
    /* padding: 50px 25px; */
    width: calc(100vw - 24px);
    padding: 50px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.color_red {
    color: red;
}

.color_yelow {
    color: yellow;
}

.color_green {
    color: green;
}

.color_.red::before,
.color_.yelow::before,
.color_.green::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid gray;
    border-radius: 7px;
    margin-right: 7px;
}

.color_.red::before {
    background: red;
}

.color_.yelow::before {
    background: yellow;
}

.color_.green::before {
    background: green;
}

.main_wrapper_block {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.main_wrapper_block_content {
    width: 100%;
    max-width: 1200px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.main_wrapper_block_content_stretch {
    width: 100%;
    max-width: 1200px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.main_wrapper_block_content_stretch_title {
    font-size: 16px;
    text-transform: uppercase !important;
}

.main_wrapper_block_content_stretch_profile {
    width: 100%;
    max-width: 720px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.how-wrapper,
.earn-with-us {
    padding: 30px 20px 40px;
    width: calc( 100% - 40px);
    max-width: 1200px;
}

.how-wrapper figure,
.earn-with-us figure {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
}

.how-wrapper figure img,
.earn-with-us figure img {
    width: 50%;
    max-width: 600px;
    min-width: 300px;
    margin: 0;
    z-index: 2;
}

.how-wrapper figure figcaption,
.earn-with-us figure figcaption {
    width: calc(50% - 28px);
    max-width: 600px;
    min-width: 300px;
    margin: 0;
    /* border-radius: 32px; */
    padding: 14px;
    /* box-shadow: 0 0 0 5px #ECF1E5; */
    position: relative;
}

.how-wrapper figure figcaption::before,
.earn-with-us figure figcaption::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 32px;
    box-shadow: 0 0 0 5px #ECF1E5;
    pointer-events: none;
}

.how-wrapper figure:nth-child(2),
.earn-with-us figure {
    justify-content: center;
    flex-direction: row-reverse;
}

@media (min-width: 800px) {
    .how-wrapper figure figcaption::before {
        width: 150%;
        left: -50%;
        z-index: 1;
    }

    .how-wrapper figure:nth-child(2) figcaption::before,
    .earn-with-us figure figcaption::before {
        right: -50%;
        left: 0;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    /* CSS-стили */
}

.how-item .how-img-1 {
    width: 330px;
    height: 307px;
    background: url(./images/how_it_work_1.svg) center bottom no-repeat;
    background-size: contain;
}

.how-item .how-img-2 {
    width: 295px;
    height: 246px;
    background: url(./images/how_it_work_2.svg) center bottom no-repeat;
    background-size: contain;
}

.how-item .how-img-3 {
    width: 330px;
    height: 261px;
    background: url(./images/how_it_work_3.svg) center bottom no-repeat;
    background-size: contain;
}

.how-item .data {
    width: 100%;
    text-align: left;
}

.how-item:nth-child(2) .data {
    text-align: right;
}

.how-item:nth-child(2) .data .text {
    text-align: right;
    /* padding-right: 0;
  padding-left: 330px; */
}

.how-item .data .title {
    font-size: 30px;
    line-height: 1;
}

.how-item .data .title {
    font-size: 30px;
    line-height: 1;
}

.how-item .data .text {
    text-align: left;
    font-size: 24px;
    line-height: 1.2;
    margin-top: 10px;
}

.why-wrapper {
    text-align: center;
    margin: 30px auto 50px;
}

h2.heading {
    text-align: center;
    text-transform: none;
    padding-bottom: 0;
    margin-bottom: 20px;
    font-size: 36px;
    color: #353535;
}

.why-wrapper .why-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto 10px;
}

.why-wrapper .why-content .why-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 300px;
    flex-shrink: 0;
    margin-bottom: 30px;
}

.why-wrapper .why-content .why-item:before {
    content: '';
    display: block;
    width: 26px;
    height: 19px;
    background: url(./images/done_black_24dp.svg);
    background-size: 26px 19px;
    margin-right: 30px;
    flex-shrink: 0;
}

.why-wrapper .why-content .why-item h2,
.why-wrapper .why-content .why-item>span {
    font-size: 24px;
    line-height: 1.3;
    text-align: left;
}

.search-button_green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 292px;
    height: auto;
    margin: 0 auto;
    font-size: 18px;
    border-radius: 10px;
    background-color: #2BB801;
    color: #fff;
    padding: 12px 30px;
    text-decoration: none;
    text-align: center;
    transition: all .1s linear;
    font-weight: 700;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
}

.search-button_green:hover {
    background-color: #57c337;
    box-shadow: 0 1px 2px rgb(0 0 0 / 80%);
}

.search-button_green:active {
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 80%);
}

.about_text {
    text-align: left;
    font-size: 24px;
    line-height: 1.2;
    margin-top: 10px;
}

.earn-with-us {
    padding: 0 0;
    /* background-color: #f3f3f3; */
    display: flex;
    justify-content: space-between;
}

.earn-with-us .earn-text {
    width: 60%;
    padding: 50px 0;
}

.earn-with-us .register-img {
    width: 40%;
    max-width: 400px;
    height: auto;
    background: url(./images/main-chart-webp.webp) center no-repeat;
    background-size: contain;
}


@media (min-width: 1024px) {
    .why-wrapper .why-content .why-item {
        width: 48%;
    }

    .main_wrapper_block_content, .main_wrapper_block_content_stretch {
        padding: 0 24px;
    }

    .main_wrapper_block_content_stretch_profile {
        padding: 24px;
    }
}