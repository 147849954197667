/* Works on Firefox */

* {
    scrollbar-width: thin;
    scrollbar-color: #2b313500 #2BB801;
}

*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-thumb {
    background-color: #2BB801;
    border-radius: 20px;
    border: 1px solid #4f6d2f;
}

*::-webkit-scrollbar-track {
    background: #2b313500;
}

.root_menu {
    scrollbar-width: thin;
    scrollbar-color: #2b313500 #da1c5b00;
}

.root_menu::-webkit-scrollbar-thumb {
    background-color: #da1c5b00;
    border-radius: 20px;
    border: 1px solid #9e1f6300;
}

.root_menu::-webkit-scrollbar-track {
    background: #2b313500;
}


/* devanagari */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}


/* latin-ext */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}


/* latin-ext */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(fonts/poppins/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* devanagari */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}


/* latin-ext */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* user-select: none; */
    max-width: 100vw;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}

.main {
    transform: scale(1) translate3d(0px, 0, 0);
    transition: transform .7s;
    border-radius: 0px;
    box-shadow: 1px 1px 18px -2px;
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
    display: block;
    /* height: 100vh; */
    /* height: calc(var(--vh, 1vh) * 100); */
    width: 100%;
    /* overflow: hidden; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    transition: transform .4s ease-in-out;
    flex-direction: column;
}

.mouse_wheel_block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 1;
}

.main_header {
    width: 100vw;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(50, 48, 46, 0.75);
    backdrop-filter: blur(3px);
    z-index: 100;
}

.main_header_content {
    width: 100%;
    max-width: 1200px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.b_menu {
    text-transform: uppercase;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 50px;
    padding: 0 20px;
    background: #282828;
    transition: all 0.2s;
    height: 0px;
    overflow: hidden;
}

.b_menu.active {
    padding: 20px 20px;
    height: auto;
    overflow: auto;
}

.b_menu.active:before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
}

.b_menu__item {
    display: inline-block;
    /* margin-right: 15px; */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: #fff;
    /* opacity: .8; */
    padding: 10px;
    z-index: 1000;
}

.b_menu__item:hover {
    text-decoration: underline;
    opacity: 1;
}

.flex {
    display: flex;
    align-items: center;
    flex-direction: row;
}

#menu_icon {
    transition: all 0.2s;
    cursor: pointer;
    color: #fff;
    font-size: 26px;
    padding: 11px;
    box-shadow: 0 0 0 0 #2BB801;
}

#menu_icon:hover {
    color: #2BB801;
}

#menu_icon.active {
    box-shadow: 0 4px 0 0 #2BB801;
}

.logo {
    text-transform: uppercase;
    color: #fff;
    transition: all 0.2s;
    height: 30px;
    top: 0px;
    left: 0px;
    margin: 0px 7px;
    display: flex;
    z-index: 200;
    transform: translateY(0vh);
    cursor: pointer;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.stop-war {
    position: fixed;
    left: 7px;
    top: 7px;
    display: block;
    height: 36px;
    width: 87px;
    background: url(./images/save_ukraine-min.svg) no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 10000;
    border: 0;
    opacity: .9;
    cursor: pointer;
}

.stop-war:hover {
    opacity: 1;
}

@media (max-width: 1360px) {
    .stop-war {
        background: url(./images/stop_war.svg) right bottom no-repeat;
        background-size: contain;
        left: auto;
        top: 90px;
        right: 0;
        width: 80px;
        height: 80px;
        opacity: .8;
    }
}

.navtoggler {
    display: table;
    padding: 8px;
    z-index: 1000;
}

.navtoggler.close span {
    width: 28px;
    margin: 6px;
    display: block;
    height: 3px;
    background: #fff;
    transition: all 0.2s;
}

.navtoggler span {
    width: 28px;
    margin: 7px;
    display: block;
    height: 2px;
    background: #fff;
    transition: all 0.2s;
    box-shadow: 0 0 0 0px #fff;
}

.navtoggler:hover span {
    box-shadow: 0 0 0 1px #2BB801;
    background: #2BB801;
}

.navtoggler.active span:first-of-type {
    transform: rotate(45deg) translate3d(7px, 6px, 0);
}

.navtoggler.active span:nth-of-type(2) {
    transform: translate3d(-200px, 0px, 0);
    opacity: 0;
}

.navtoggler.active span:last-of-type {
    transform: rotate(-45deg) translate3d(6.5px, -6px, 0px);
}

.main.menu_active {
    transform: scale(.8) translate3d(-400px, 0, 0);
    border-radius: 42px;
    box-shadow: 1px 1px 18px -2px;
}



.article_result {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.article {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
}


#svg_logo {
    position: absolute;
    z-index: 90;
    transform-origin: center center;
    transform: translate(0px, -5vh);
    width: 65vh;
    height: 65vh;
}

.image_z_logo {
    width: 77px;
    height: 77px;
    -webkit-clip-path: url(#my-clip-path);
    clip-path: url(#my-clip-path);
    position: relative;
    overflow: hidden;
    transform-origin: center center;
    z-index: -1;
    border-radius: 50%;
    margin: 0;
    margin-left: -10px;
    /* transform: translate(-600px, 300px) rotate(-360deg) scale(1) ; */
    transition: 1.5s ease-in-out;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
}

.logo h1 {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 36px;
    line-height: 30px;
    font-weight: 400;
}

.logo h1 strong {
    margin: 0;
    padding: 0;
    color: #2BB801;
    display: block;
    letter-spacing: 2px;
    font-weight: 600;
}

.logo img {
    height: 60px;
    width: 82.73;
}

.drop_menu {
    margin-right: 7px;
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    display: flex;
    z-index: 200;
    transition: .7s;
    width: auto;
    min-height: 50px;
    /* top: 0px;
    right: 86px; */
    align-items: center;
    justify-content: center;
}

.drop_menu a {
    color: inherit;
    text-decoration: none;
}

.drop_menu-current {
    z-index: 13;
    font-size: 14px;
    display: flex;
    position: relative;
    text-transform: uppercase;
    align-items: center;
}

.avatar_preview {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0 0 2px 0px #000, 0 0 0px 1px #fff; */
    background-size: cover;
    background-position: center;
    margin: 5px;
}

.drop_menu .avatar_preview {
    margin: 0 0 0 5px;
}

.drop_menu-dropdown {
    position: absolute;
    top: 100%;
    padding: 0;
    font-size: 13px;
    box-shadow: none;
    margin-top: 0px;
    display: block !important;
    background-color: rgba(50, 48, 46, 0.75);
    backdrop-filter: blur(3px);
    width: max-content;
    text-transform: uppercase;
    border-top: 4px solid #2BB801;
    transform: translate3d(0, -40px, 0);
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    transition-delay: .2s;
}

.drop_menu-dropdown ul,
.drop_menu-dropdown li {
    margin: 0;
}

.drop_menu-dropdown button,
.drop_menu-dropdown .button {
    padding: 16px;
    width: 100%;
    text-align: center;
    transition: color .25s linear;
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    background: none;
    border: 0;
    cursor: pointer;
}

.drop_menu-dropdown a.button {
    padding: 16px 12px;
    width: calc(100% - 24px);
}

.drop_menu-dropdown a {
    padding: 7px;
    display: block;
    text-align: left;
    transition: color .25s linear;
    font-family: Roboto;
    font-size: 12px;
    color: #FFFFFF;
    cursor: pointer;
}

.drop_menu-dropdown button:hover,
.drop_menu-dropdown .button:hover,
.drop_menu-dropdown a:hover {
    color: #ffffff;
    background-color: #2BB801;
}

.drop_menu:hover .arrow {
    transform: rotate(180deg);
}

.drop_menu-current .arrow {
    width: 12px;
    margin-left: 7px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop_menu .arrow {
    position: relative;
    top: -1px;
    transform: rotate(0);
    transition: transform .17s linear;
    transform-origin: center;
}

.drop_menu:hover {
    color: #2BB801;
}

.drop_menu:hover .drop_menu-dropdown {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
    pointer-events: all;
    transition-delay: 0s;
}

.drop_menu:hover .st0 {
    fill: #2BB801;
}

.drop_menu .st0 {
    transition: color .25s linear;
    fill: #fff;
}

.event_img {
    padding: 6px;
}

.event_red {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 3px;
    right: 3px;
    padding: 3px;
    min-width: 12px;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    background: red;
    color: white;
    border-radius: 9px;
}

.red_event {
    float: right;
    padding: 0 3px;
    border-radius: 5px;
    background: red;
    margin-left: 5px;
    min-width: 12px;
    text-align: center;
}

.header_content {
    /* position: absolute; */
    /* display: block; */
    /* height: 100vh; */
    min-height: calc((var(--vh, 1vh) * 100) - 100px);
    width: 100vw;
    /* overflow: hidden; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(./images/background.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0px;
}

@media (min-width: 1024px) {}

@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -60px 0px;
        /* анимируем свойство background-position */
    }
}

.header-home {
    padding-top: 70px;
    padding-bottom: 75px;
    background-size: cover;
    background-position: 42%;
    min-width: 80vw;
}

.header-home .main-page__heading {
    margin: 0 15px;
    max-width: 900px;
}

.header-home h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 65px;
    color: #f7f7f7;
    margin: 0 15px;
    max-width: 900px;
}
.header-home h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
    color: #f7f7f7;
    margin: 0 15px;
    max-width: 900px;
}

.header-home .main-page__form {
    /* margin: 30px 15px 0; */
    max-width: 900px;
    /* max-width: 1067px; */
    margin: 65px 15px 0;
    text-align: left;
}

.header-home .main-page__form .wrapper {
    margin: 0 auto;
}

.header-home .main-page__form .wrapper .tab-body {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .8);
    max-width: 90%;
    width: auto;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 45%);
    backdrop-filter: blur(3px);
}

.header-home .main-page__form .wrapper .tab-body .wide-search {
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-wrap: wrap;
}

.wide-search .search_form,
.wide-search .wide-search__button {
    margin: 5px;
    flex: 1;
    min-width: 180px;
}

.wide-search .checkbox_container {
    margin: 5px;
    flex: 1;
    align-self: end;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .filters-wrapper {
    flex-direction: column;
    margin-bottom: 0;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .filters-wrapper .wide-search-item {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 0;
    display: flex;
    align-items: center;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .input-box-wrapper {
    display: inline-block;
    width: 100%;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .input-box-wrapper .input_box {
    border: 1px solid transparent;
}

select.list.filters_list {
    width: 100%;
    height: 35px;
    line-height: 30px;
    padding: 5px;
    border: 1px solid #aaa;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

select.list {
    display: block;
    line-height: 40px;
    height: 36px;
    padding: 10px;
    vertical-align: middle;
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

input,
select,
textarea {
    margin: 0;
    padding: 0;
    font: 14px/1.3 arial, sans-serif;
    background: #fff;
    border: none;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    color: #464C48;
    /* position: relative;
  z-index: 1; */
}

.header-home .main-page__form .wrapper .tab-body .wide-search .online-wrapper {
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 20px;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .online-wrapper label.filled {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #353535;
    font-size: 18px;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .online-wrapper label.filled>input[type=checkbox]+span {
    padding-left: 40px;
}

.header-home .main-page__form .wrapper .tab-body .wide-search .online-wrapper label.filled>input[type=checkbox]+span:before {
    width: 26px;
    height: 26px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    top: 0;
    background-color: #fff;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}


/* input[type=checkbox]+span:before, input[type=radio]+span:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: solid #2BB801 1px;
  background: #fff;
  -webkit-box-shadow: inset 1px 1px 1px rgb(0 0 0 / 30%);
  box-shadow: inset 1px 1px 1px rgb(0 0 0 / 30%);
  position: absolute;
  z-index: 0;
  top: 1px;
  left: 0;
} */

.header-home .main-page__form .wrapper .tab-body .wide-search__button {
    float: none;
    width: 94%;
    margin: 0 auto;
    min-width: 200px;
    align-self: center;
}

.header-home .main-page__form .wrapper .tab-body .wide-search__button a {
    width: auto;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    height: 50px;
    border-radius: 10px;
    line-height: 1;
}

.header-home .main-page__form .select2-container--default .select2-selection--single {
    height: 40px !important;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid #fff !important;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
}

.header_image_block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    justify-content: space-around;
    align-content: space-around;
    flex-direction: row;
    align-items: flex-end;
}

.header_image_block figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.header_image_block img,
.header_image_block .header_image_block_inner {
    width: calc(100vw - 50px);
    max-width: 600px;
}

.header_image_block figcaption {
    font-size: 24px;
    line-height: 1.2;
    margin-top: 10px;
}

#how_it_works {
    background-color: #f3f3f3;
    padding: 30px 0 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    width: 100%;
    max-width: 900px;
    color: #353535;
}

.input_error {
    color: red;
    line-height: 150%;
}

.input_error_popup:not(:empty) {
    color: red;
    line-height: 150%;
    position: absolute;
    bottom: 0;
    padding: 7px;
    background: #282828;
    border: 1px solid red;
    z-index: 2;
}

.input_error_popup {
    display: none;
}

#wrap_radio label {
    position: relative;
    height: 100%;
    display: block;
    height: 50px;
}

#wrap_radio [type="radio"] {
    display: none;
    z-index: 5;
    position: relative;
}

#wrap_radio [type="radio"]~span {
    transition: background .3s;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    z-index: 5;
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 22px;
    text-transform: inherit;
    color: #555555;
}

#wrap_radio [type="radio"]:checked~span {
    color: #FFFFFF;
}

#wrap_radio .wrap_radio__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #2BB801;
    top: 0;
    z-index: 0;
    left: 0;
    width: 0;
    transition: .3s width;
}

#wrap_radio label:nth-child(1) .wrap_radio__bg {
    right: 0;
    left: auto;
}

#wrap_radio label:nth-child(2) .wrap_radio__bg {
    left: 0;
}

#wrap_radio [type="radio"]:checked+.wrap_radio__bg {
    width: 100%;
}

#wrap_radio {
    border: 1px solid #2BB801;
    display: inline-flex;
    overflow: hidden;
    border-radius: 50px;
}

.table_header {
    height: auto;
    min-height: auto;
    padding-top: 50px;
    padding-bottom: 60px;
    background: url(./images/background.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

#register.table_header form {
    width: auto;
    padding: 0 14px;
    margin: 30px auto 0;
    justify-content: center;
    background-color: rgba(239, 239, 239, .85);
    border-radius: 7px;
    display: table;
    max-width: 700px;
}

#register.table_header form h1 strong {
    white-space: break-spaces;
    font-size: 20px;
    line-height: 1.2;
    outline: 0;
    color: #555;
    text-align: center;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#register.table_header form h2 {
    font-size: 18px;
    padding: 0 30px 40px;
    color: #353535;
    line-height: 22px;
}

.b_btn.btn-green {
    background-color: #2BB801;
    color: #fff;
    line-height: 1.1;
    padding: 12px;
    margin: 7px;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    font-weight: 700;
    cursor: pointer;
    border: none;
}

.full-name {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #353535;
    font-size: 28px;
}

.drag_n_drop_img {
    width: 240px;
    height: 240px;
    background: url(./images/avatar_area.png) 0 center no-repeat;
    background-size: cover;
}

.image_icon {
    width: 40px;
    min-width: 40px;
    height: 37px;
    margin: 0;
    background: url(./images/image_black_24dp.svg) center no-repeat;
    background-size: cover;
}

.float_horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #2BB801;
    max-width: 500px;
}

.diplomas img {
    width: 100%;
}

.float_horizontal p {
    align-items: left;
    margin: 10px 12px;
}

.garbage:hover {
    opacity: 1;
}

.garbage {
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: auto;
    opacity: .7;
    background: url(./images/delete_black_24dp.svg) center no-repeat;
    background-size: 22px;
}

.w-100 {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    font-size: 200px;
    opacity: 0;
    cursor: pointer;
}

.edit_button {
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    background: url(./images/drive_file_rename_outline_black_24dp.svg) 0 center no-repeat;
    background-size: contain;
    opacity: .8;
    cursor: pointer;
    align-items: center;
}

.btn-wrap-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input_confirm {
    color: #ef0f0f;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    padding-top: 5px;
    cursor: pointer;
}

.input_confirm.ok {
    color: #0049d3;
}

.add_lesson,
.add_files {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: #2BB801;
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}

.add_lesson:before,
.add_files:before {
    content: "";
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 15px;
    background-image: url(./images/add_circle_black_24dp.svg);
    background-size: 26px;
}

.w-add_files {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.b_btn.btn-green:hover {
    background-color: #57c337;
    color: #fff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 80%);
}

.b_btn.btn-green:active {
    -webkit-box-shadow: inset 0 1px 3px rgb(0 0 0 / 80%);
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 80%);
}

.b_btn.btn-green:disabled {
    background-color: #494c44;
    color: rgb(160, 158, 158);
    box-shadow: 0;
}

#register.table_header form p {
    text-align: center;
    color: #313131;
    font-size: 14px;
    margin-top: 10px;
}

#register.table_header form .btn-wrap {
    justify-content: center;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 12px;
}

.button-down-white {
    width: 40px;
    height: 45px;
    background: url(./images/chevron-arrow-up-white.svg) no-repeat;
    background-size: 40px 45px;
    z-index: 3;
    transform: rotate(180deg);
    display: block;
}

.table-content {
    display: flex;
    flex-wrap: nowrap;
    color: #6b6b6b;
    width: 100%;
    background-color: #FFFFFF;
    flex-direction: column;
    align-items: center;
}

.table-content p {
    padding: 0 10px;
    text-align: center;
}

.profile-field_flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-field_flex_right {
    width: auto;
    margin-left: 20px;
}

.profile-field h3 {
    margin-bottom: 3px;
}

.profile-field_input {
    width: auto;
    padding: 7px;
    font-size: 22px;
    border-bottom: 1px solid green;
    display: block;
    width: 94%;
}

.profile-field_textarea {
    resize: vertical;
    width: auto;
    padding: 7px;
    font-size: 22px;
    border-bottom: 1px solid green;
    display: block;
    width: 94%;
}

.schedule * {
    box-sizing: border-box;
}

.schedule {
    flex-wrap: nowrap;
    display: flex;
    flex-wrap: nowrap;
    padding: 5px 1%;
    font-size: 14px;
    margin: 0;
    padding: 0;
    border: 0;
}

.schedule-cell.day-cell .desktop {
    display: none;
}

.schedule-row {
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.schedule-row:hover .day-cell:not(:empty),
.schedule-cell.time-cell.active-time,
.schedule-wrapper .schedule-cell label:hover {
    /* background: #2BB801; */
    border: 3px solid #2BB801;
    color: #3d7209;
}

.schedule-row.title-row {
    min-width: auto;
    width: 100%;
    font-weight: 700;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.schedule-cell.time-cell {
    width: auto;
    padding: 0 2px;
    font-weight: 400;
    text-align: center;
    display: flex;
    border: 1px solid #ccc;
    height: 27px;
    align-items: center;
    justify-content: center;
}

.schedule-cell.day-cell {
    min-width: auto;
    width: 100%;
    height: 27px;
    min-width: 8%;
    text-align: center;
    font-weight: 700;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedule-wrapper {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.schedule-wrapper .schedule-cell {
    flex-wrap: wrap;
    width: 50%;
    height: 27px;
    text-align: center;
    /* border: 1px solid #ccc; */
}

.schedule-wrapper .schedule-cell .cell-input {
    display: none;
}

.schedule-wrapper .schedule-cell label {
    display: block;
    width: auto;
    height: 27px;
    border: 1px solid #ccc;
}

.schedule-wrapper .schedule-cell input[type=checkbox]:checked+label {
    width: auto;
    background-color: #2BB801;
    /* border: 1px solid #2BB801; */
}

.available_active {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #2BB801;
    vertical-align: middle;
}

.available_time_for_classes {
    display: flex;
    align-items: center;
}

.available_time_for_classes label {
    display: inline-block;
    width: 27px;
    height: 27px;
    background-color: #2BB801;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-right: 7px;
}

.subject_field {
    position: relative;
}

.remove {
    display: block;
    position: absolute;
    top: 10px;
    right: 6%;
    height: 15px;
    width: 15px;
    opacity: .7;
    background-size: contain;
    background: url(./images/highlight_off_black_24dp.svg) 0 center no-repeat;
    z-index: 1;
}

.remove:hover {
    opacity: 1;
}

#register.table_header form.form_edit_pass {
    width: 100%;
    padding: 0px;
    margin: 15px auto;
    justify-content: center;
    background-color: rgba(239, 239, 239, 0);
    border-radius: 0px;
    display: table;
    max-width: 700px;
}

#register.table_header form.form_edit_pass .profile-field_input,
#register.table_header form.form_edit_pass .btn-wrap {
    margin: 5px 0;
}

.input_pass_block {
    display: block;
    position: relative;
}

.pass_toggle {
    display: block;
    position: absolute;
    top: 10px;
    right: 6%;
    height: 20px;
    width: 20px;
    opacity: .7;
    background-size: contain;
    background: url('./images/eye-regular.svg') 0 center no-repeat;
    z-index: 1;
    cursor: pointer;
}

.pass_toggle:hover {
    opacity: 1;
}

.pass_toggle.active {
    background: url('./images/eye-slash-regular.svg') 0 center no-repeat;
}

footer {
    width: auto;
    max-width: 1200px;
    padding: 0px 24px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    text-decoration: none;
    color: #fff;
}

footer .menu {
    width: auto;
    max-width: 1200px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    text-transform: uppercase;
}

footer ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
}

footer ul,
footer li {
    margin: 0;
    padding: 0;
}

footer .bottom {
    text-align: center;
    margin: 7px auto 14px;
    border-top: 1px solid #2ab802;
}

#radio_tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 14px;
}

#radio_tabs label {
    margin: 7px;
    padding: 7px;
    border: 1px solid green;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
    min-height: 40px;
}

#radio_tabs .wrap_radio__bg {
    position: absolute;
    width: 0%;
    height: 100%;
    background: #2BB801;
    top: 0;
    z-index: 0;
    left: 0;
    width: 0;
    transition: width .3s;
}

#radio_tabs [type=radio] {
    display: none;
    z-index: 5;
    position: relative;
}

#radio_tabs [type=radio]:checked+.wrap_radio__bg,
#radio_tabs label:hover .wrap_radio__bg {
    width: 100%;
}

#radio_tabs [type=radio]~span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    z-index: 5;
    position: relative;
    color: #555;
}
#radio_tabs [type=radio]~span a{
    pointer-events: none;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    text-transform: inherit;
    color: inherit;
}

#radio_tabs [type=radio]:checked~span,
#radio_tabs label:hover span {
    color: #fff;
}

.section_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.section_block .section_item {
    display: flex;
    flex: 1 1 auto;
    text-align: center;
    background-color: aliceblue;
    padding: 7px 14px;
    margin: 5px;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-height: 40px;
    text-decoration: none;
    color: #353535;
}

.section_block .section_item:hover {
    background: #2BB801;
    color: #fff;
}

.test_block {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: url(./images/background.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    color: aliceblue;
}

.test_block input {
    padding: 14px;
    color: cadetblue;
    font-weight: 700;
}

.public_text h1 {
    margin: 0.2em 0;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

.public_text h2 {
    margin: 0.2em 0;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

.public_text h3 {
    margin: 0.2em 0;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}


/* word-wrap: break-word; */


/* Перенос слов */

.public_text p {
    margin: 0.2em 0;
}

@media (max-width: 350px) {
    .public_text h1 {
        font-size: 1em;
    }

    .public_text h2 {
        font-size: 0.9em;
    }

    .public_text h3 {
        font-size: 0.8em;
    }

    .public_text p {
        font-size: 0.7em;
    }
}

.article h1,
.article h2,
.article h3 {
    font-size: 21px;
}

.article p {
    font-size: 18px;
}

.article_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 15px;
}

.article_right {}

.sections_select {
    margin: 7px;
    padding: 14px;
    border: 1px solid green;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
    min-height: 40px;
    font-size: 20px;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 25px) calc(1em + 2px), calc(100% - 20px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

details {
    border: 1px solid green;
}

summary {
    background: #bce790;
    padding: 7px;
}

details .text {
    padding: 7px;
}

.editable_block {
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 20px rgba(128, 128, 128, 0);
    margin: 12px 0;
    padding: 12px;
    position: relative;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .5s;
}
.editable_block_header{
    max-width: 100%;
    background: #fff;
    padding: 3px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    line-height: 12px;
}
.editable_block_content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 14px;
}

.editable_block.active {
    box-shadow: 1px 1px 20px grey;
    z-index: 10;
    background-color: white;
}

.editable_title {
    color: #333;
    resize: none;
    width: calc( 100% - 8px );
    background-color: rgba(42,184,2,.14);
    border: none;
    box-shadow: 0 0 0 1px #2ab802;
    border-radius: 3px;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 12px;
    padding: 4px;
}

.editable_block .edit_button {
    padding-right: 7px;
    padding-left: 25px;
    width: auto;
    border-radius: 7px;
    font-size: 16px;
}

.editable_block .edit_button:hover {
    box-shadow: 0 0 3px #2ab802;
}

.editable_block .search-button_green.edit {
    width: auto;
    padding: 4px;
    display: inline-flex;
    border-radius: 7px;
    font-size: 14px;
    margin: 0 3px 0;
    align-items: center;
}


.search-button_green:disabled,
.search-button_green:disabled:hover {
    background-color: #b0b0b0;
    box-shadow: none;
}
.search-button_green.send_message_button {
    width: auto;
    margin: 0 0 18px 0;
    max-width: 750px;
}

.editable_block .edit_url {
    padding: 7px;
    /* padding-left: 25px; */
    width: auto;
    border-radius: 7px;
    font-size: 12px;
    color: #979797; 
    word-break: break-word;
}

.editable_block .edit_icon_empty,
.editable_block .edit_icon_load,
.editable_block .edit_icon_done{
    position: relative;
    width: auto;
    padding: 3px 0;
    margin: 3px;
    /* padding-right: 21px; */
    padding-left: 5px;
    min-height: 15px;
    display: inline-flex;
    border-radius: 7px;
    font-size: 14px;
    color: #212121;
    background-color: #29b80231;
    align-items: center;
}
.editable_block .edit_icon_empty{
    background-color: #b8020231;
}


.editable_block .edit_icon_empty::after,
.editable_block .edit_icon_load::after,
.editable_block .edit_icon_done::after {
    background-position: center center;
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: no-repeat;
    background-size: inherit;
    margin: 0 5px;
}
.editable_block .edit_icon_empty::after {
    background-image: url(./images/xmark-solid.svg);
}

.editable_block .edit_icon_load::after {
    background-image: url(./images/rotate-right-solid.svg);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.editable_block .edit_icon_done::after {
    background-image: url(./images/check-solid.svg);
}

.seo_content{
    
}

.index_main_block{
    width: 100%;
    max-width: 1200px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.block_404{    
    min-height: calc( 100vh - 100px);
    padding: 50px 25px;
    background: #eff9f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img_404{
    max-height: 50vh;
    width: calc( 100vw - 50px );
}





.article_blog .article_body {
    background: #eeeff0;
    padding: 12px;
    margin: 0 -12px;
    margin-bottom: 14px;
    border-radius: 0px;
}

.article_blog .article_body.deleted{
    background: #00000040;
}
.article_blog .article_author {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.article_blog .article_author_media {
    margin-right: 15px;
    display: block;
    width: 90px;
    max-width: 90px;
    height: 90px;
    max-height: 90px;
    border-radius: 50%;
    background-size: cover;
    background-position: 50%;
}
.article_blog .article_author_descr {
    padding: 0;
    width: calc(100% - 90px);
    line-height: 18px;
}

.article_blog .article_author_name {
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: #2ab802;
    line-height: 1.2em;
    font-weight: 700;
}
.article_blog .article_date {
    font-size: 13px;
    line-height: 18px;
    text-transform: none;
    text-decoration: none;
    color: #888;
    font-weight: 300;
}
.article_blog .article_content {
    padding: 5px 0 5px 0;
}
.article_blog .article_header {
    /* font-size: 2.1em; */
    padding: 0 0 8px 0;
    /* line-height: 1.2em; */
}
.article_blog .article_header h1 {
    text-decoration: none;
    color: #1a1a1a;
    font-size: 26px;
    line-height: 36px;
}
.article_blog .article_header h1 a{
    /* color: #2ab802;     */
    text-decoration: none;
    color: #1a1a1a;
    font-size: 26px;
    line-height: 36px;
}
.article_blog .article_header h1 a:hover{
    color: #2ab802;    
}

.article_blog .article_description {
    font-size: 16px;
    margin: 0; /* Убираем внешний отступ */
    /* -webkit-line-clamp: 3; Число отображаемых строк */
    /* display: -webkit-box; Включаем флексбоксы */
    /* -webkit-box-orient: vertical; Вертикальная ориентация */
    /* overflow: hidden; Обрезаем всё за пределами блока */
   }

   .article_blog  .read_more{
    font-size: 13px;
    line-height: 18px;
    text-transform: none;
    text-decoration: none;
    color: #888;
    font-weight: 300;
    display: block;
    margin-top: 12px;
    text-align: right;
}
.article_blog .read_more:hover{
    color: #2ab802;    
}
.article_description .ql-editor {
    padding: 0;
}
.label-date{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.label-date:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(./images/calendar.svg) no-repeat center/contain;
    margin-right: 6px;
}

.social-icon{
    margin: 0 4px;
    color: aliceblue;
    text-decoration: none;
}

.social-icon:hover{
    color: #2ab802;
}

.social-icons.fa {
    width: 29px;
    height: 29px;
    font-size: 18px;
    color: white;
    text-decoration: none;
    margin: 4px;
    margin-right: 7px;
    border-width: 1px;
    border-color: transparent;
    border-radius: 50%;
    display: flex;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    backface-visibility: hidden;
    transition: all 0.1s ease;
    justify-content: center;
    align-items: center;
}
.social-icons.fa:hover{
animation: animation-bounce 0.3s forwards;
}
.social-icons::after{
    content: '';
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
}

.social-icons.fa-instagram::after{
    background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}
.social-icons.fa-facebook-f::after {
    background-color: #3e68c0;
}


@keyframes animation-bounce {
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 1);
        transform: scale3d(0.9, 0.9, 1);
    }
    70% {
        -webkit-transform: scale3d(1.05, 1.05, 1);
        transform: scale3d(1.05, 1.05, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@media (min-width: 1024px) {
    body {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .navtoggler span {
        display: none;
    }

    .b_menu,
    .b_menu.active {
        display: flex;
        position: inherit;
        padding: 0;
        background: none;
        height: auto;
        flex-direction: row;
        align-items: center;
    }

    .b_menu__item {
        font-size: 12px;
    }

    .App {
        margin: 0 50px;
    }

    .root_menu {
        transition: transform .7s;
        transform: translate3d(400px, 0, 0);
        z-index: 100;
    }

    .root_menu.menu_active {
        transform: translate3d(-100px, 0, 0);
    }

    .main {
        box-shadow: 0 0 0 0;
    }

    .main.menu_active {
        transform: scale(1) translate3d(-500px, 0, 0);
        box-shadow: 0 0 0 0;
        border-radius: 0px;
    }

    .main::before {
        content: '';
        transition: width .5s;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 0vw;
        background: #20232abd;
        z-index: 300;
    }

    .main.menu_active::before {
        width: 100vw;
    }

    .logo {
        margin: 0 25px;
    }

    .drop_menu-current .arrow {
        width: 18px;
        margin-left: 7px;
        height: 18px;
    }


    .editable_block {
        margin: 12px;
    }


    .article_result {
        margin: 15px;
    }
    
    .article {
        padding: 13px 15px;
        border-radius: 4px;
        margin-bottom: 15px;
    }


    .article_blog .article_body {
        padding: 24px;
    }

    .index_main_block {
        width: calc(100% - 48px);
        padding: 24px;
    }


}
