.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    padding: 0;
    background-color: rgba(0, 0, 0, .75);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-content {
    position: relative;
    margin: auto;
    max-width: 644px;
    width: calc(100% - 14px);
    background-color: #f3f3f3;
    border-radius: 10px;
    /* border: none; */
    padding: 7px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modal-content .close,
.close_button {
    transform: scale(1.6);
    background: url(../images/highlight_off_black_24dp.svg) center no-repeat;
    background-size: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 20;
    opacity: 0.8;
}

.modal-content .close:hover,
.close_button:hover {
    transform: scale(1.8);
    opacity: 1;
}

.modal-content_error {
    color: lightcoral;
    margin-top: 25px;
    margin-bottom: -25px;
}

.load_submitted {
    background: url(../images/preloader.gif) center no-repeat rgba(255, 255, 255, 0.5);
    background-size: 115px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 10px;
    margin: -25px;
}

.modal-content img.user-photo {
    display: block;
    background-color: #fff;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    top: -75px;
    margin: 0 auto -75px;
    position: relative;
}

.modal-content .user-photo {
    display: block;
    background-color: #fff;
    width: 116px;
    height: 116px;
    border-radius: 50%;
    top: -75px;
    margin: 0 auto -75px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.modal-content .user-name {
    font-size: 24px;
    font-weight: 400;
}

.steps-indicator {
    margin: 10px auto 5px;
    display: flex;
    align-items: center;
}

.steps-indicator>span {
    display: flex;
    align-items: center;
}

.steps-indicator>span.active {
    font-size: 24px;
    color: #2BB801;
    font-weight: 700;
}

.steps-indicator>span:not(:last-child):after {
    content: "";
    display: inline-block;
    border-bottom: 3px solid #2BB801;
    width: 12px;
    margin: 0 35px;
}

.steps-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.request-wrapper {
    margin-left: 0px;
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
}

.request-wrapper-column {
    /* margin-left: 40px; */
    display: flex;
    align-items: stretch;
    padding: 10px 0;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    flex-direction: column;
}

.request-wrapper p {
    margin: 7px 0 0;
}

.modal-content select {
    font-size: 20px;
    height: 33px;
    line-height: 30px;
    color: rgb(53, 53, 53);
    padding-right: 20px;
    max-width: 280px;
    width: 100%;
}

.modal-content .checkbox_container .checkmark {
    width: 20px;
    height: 20px;
}

.modal-content .checkbox_container {
    margin: 0;
    margin-left: 0;
    padding-left: 25px;
    font-size: 20px;
    width: 255px;
    margin-top: 12px;
}

.modal-content .checkbox_container select {
    margin: 6px 0 0;
    width: 255px;
}

.modal-content .checkbox_container:first-child {
    margin-top: 0px;
}

.modal-content .checkbox_container .modal-content .checkmark {
    width: 20px;
    height: 20px;
}

.modal-content .checkbox_container .checkmark:after {
    left: 7px;
    top: 2px;
}

.modal-content input {
    margin: 6px 0 0;
    width: 245px;
    padding: 0 5px;
    font-size: 20px;
    height: 33px;
    line-height: 30px;
    color: rgb(53, 53, 53);
    position: relative;
    opacity: 1;
    cursor: pointer;
}

.request-wrapper-column textarea {
    max-height: 200px;
    min-height: 81.1875px;
    height: 69px;
    /* width: 100%; */
    resize: none;
    padding: 5px 15px;
}

.modal-content .request-step-buttons {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 15px;
    width: 100%;
}

.modal-content .button_red {
    display: inline-block;
    margin-top: 0;
    padding: 5px;
    margin-right: 0;
    height: auto;
    width: auto;
    min-width: auto;
    position: absolute;
    left: 0;
    font-size: 16px;
    text-align: left;
    background: 0 0;
    color: #abaca9;
    border: none;
}

.modal-content .button_green {
    display: block;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    min-width: 230px;
    padding: 19px 0;
    max-width: 50%;
    height: auto;
    margin: 25px auto;
    background-color: #2BB801;
    color: #fff;
    text-align: center;
}

.modal-content .link_green {
    font-size: 18px;
    font-weight: 700;
    color: #2BB801;
    text-align: center;
}



@media (min-width: 1024px) {
.modal-content {
    width: calc(100% - 50px);
    padding: 25px;
}
.request-wrapper {
    margin-left: 40px;
    width: 80%;
}

.request-wrapper-column{
    width: 80%;
}

}