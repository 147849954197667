.pagination_container {
    position: sticky;
    bottom: 0;
    display: flex;
    max-width: 100%;
    /* width: auto; */    
    width: max-content;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    /* position: relative; */
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    color: #202124;
    border-radius: 20px;
    min-height: 24px;
    line-height: 24px;
    border: 1px solid #dadce0;
    margin: 16px 16px 22px 16px;
    padding: 0px;
    overflow: hidden;
    align-self: center;
}

.pagination_prev {
    cursor: pointer;
    flex: 1 1 auto;
    padding: 8px 13px;
}

.pagination_cuppent {
    padding: 8px 13px;
}

.pagination_next {
    cursor: pointer;
    flex: 1 1 auto;
    padding: 8px 13px;
}

.pagination_more {
    cursor: pointer;
    flex: 1 1 auto;
    padding: 8px 13px;
}

.pagination_prev svg,
.pagination_next svg,
.pagination_more svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    opacity: .6;
    transition: all .5s ease-in-out;
    transform-origin: center;
    top: 0;
    bottom: 0;
    margin: auto;
}

.pagination_prev svg {
    left: 7px;
    transform: rotate(90deg);
}

.pagination_next svg {
    right: 7px;
    transform: rotate(-90deg);
}

.pagination_more svg {
    left: 7px;
    transform: rotate(0deg);
}

.pagination_prev:hover svg {
    left: 5px;
    opacity: 1;
}

.pagination_next:hover svg {
    right: 5px;
    opacity: 1;
}

.pagination_more:hover svg {
    transform: translate3d(0, 2px, 0);
    opacity: 1;
}

.pagination_container.dots {
    align-items: stretch;
}

.pagination_container.dots div,
.pagination_container.dots .pagination_prev,
.pagination_container.dots .pagination_next {
    margin: 0;
    display: flex;
    padding: 7px;
    min-width: 15px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-right: 1px solid #dadce0;
    height: auto;
    transition: all .5s;
}
.pagination_prev.disabled,
.pagination_next.disabled{
    opacity: .5;
    pointer-events: none;
}

.pagination_container.dots div:not(.dot):not(.current):hover {
    background-color: #29b80227;
    cursor: pointer;
}


.pagination_container.dots div:first-child {
    padding-left: 14px;
}

.pagination_container.dots div:last-child {
    padding-right: 14px;
    border-right: 0 solid #dadce0;
}

.pagination_container.dots div.current {
    color: white;
    font-weight: 700;
    background-color: #2ab802;
}