.container {
    /* width: 90vw; */
    max-width: 1200px;
    /* padding: 0px 5vw; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    background-color: whitesmoke;
}

.left_menu {
    width: max-content;
    background-color: rgb(255, 255, 255);
    border: 1px solid #2BB801;
    border-radius: 10px;
    overflow: auto;
    margin-right: 14px;
    width: 230px;
    margin-top: 14px;
}

.left_menu ul {
    margin: 0;
    padding: 0;
}

.left_menu li {
    margin: 0;
    padding: 7px 9px;
    font-size: 16px;
    color: #353535;
    text-align: left;
    box-shadow: 0 1px 0 0 green;
}

.left_menu li:hover {
    background-color: rgba(0, 158, 0, 0.068);
}

.left_menu li.active {
    background-color: #2BB801;
    color: whitesmoke;
}

.tabs_container {
    /* margin-left: 5px; */
    /* max-width: 950px; */
    position: relative;
    flex-grow: 2;
    max-width: 720px;
    width: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.order_card {
    max-width: 720px;
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
    font-size: 16px;
    color: #353535;
    text-decoration: none;
    width: auto;
}

.order_card_fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: whitesmoke;
    z-index: 1000;
}

.order_card_fixed_inner {
    flex-basis: 100%;
}

.order_card a {
    text-decoration: none;
    color: #353535;
}

.order_card p {
    font-size: 13px;
    margin: 5px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.row_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row_wrap_end {
    flex-wrap: wrap;
    justify-content: end;
    display: flex;
    flex-direction: row;
}

.dashed_border {
    padding: 7px;
    border: 2px dashed deeppink;
    border-radius: 14px;
}

.date_input {
    font-weight: bold;
    border: 1px solid #2BB801;
    padding: 7px;
    margin: 7px;
}

.row_wrap_space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.order_card .row_wrap p:first-child {
    width: 40%;
}

.new_link_users_attr.color_0 {
    float: right;
    background-color: rgb(236, 140, 49);
}

.new_link_users_attr.color_1 {
    float: right;
    background-color: rgb(236, 233, 49);
}

.new_link_users_attr.color_2 {
    float: right;
    background-color: rgb(149, 236, 49);
}
.new_link_users_attr.color_2.right {    
    position: absolute;
    right: 5px;
}

.new_link_users_attr.bgcolor_red {
    float: right;
    background-color: red;
    color: white;
}

.new_link_users_attr.bgcolor_yellow {
    float: right;
    background-color: rgb(236, 233, 49);
}

.new_link_users_attr.bgcolor_green {
    float: right;
    background-color: rgb(149, 236, 49);
}

form#user_data {
    width: auto;
    max-width: 950px;
    display: flex;
    flex-direction: column;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #e5e5e5;
    margin-bottom: 15px;
    font-size: 16px;
    color: #353535;
}

.message_block {
    height: calc(100vh - 350px);
    max-height: 80vh;
    overflow-y: auto;
}

.message_card {
    background: aliceblue;    
    background: linear-gradient(12deg, rgba(167,222,113,0.5) 25%, rgba(42,184,2,0.5) 75%);
    background-attachment: fixed;
    padding: 12px;
    margin: 7px;
    font-size: 12px;
}

.message_left {
    text-align: left;
    border-radius: 12px 12px 12px 0;
    margin-right: 20px;
}

.message_right {
    background-image: linear-gradient(12deg, rgba(113,218,222,0.5) 25%, rgba(2,136,184,0.5) 75%);
    text-align: right;
    border-radius: 12px 0 12px 12px;
    margin-left: 20px;
}

.message_card.message_right.nowrite {
    background: #f3f3f3
}

.text_area {
    height: 64px;
    padding: 7px;
    font-size: 18px;
    display: block;
    width: auto;
    border: 1px solid gainsboro;
    margin: 12px 0;
    resize: none;
}

.avatar_block {
    display: flex;
    align-items: center;
}

.order_card p.box-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100vw - 160px);
    white-space: nowrap;
    display: block;
}

.alert_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 720px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 15px;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 15px;
    font-size: 16px;
    color: #353535;
    text-decoration: none;
    box-shadow: 0px 10px 100px black, 0px 2px 15px -5px black;
}

.form_fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 22px;
    border-radius: 7px;
    box-shadow: 0 0 50px -10px black, 0px 5px 15px 0px black;
    /* overflow: auto; */
    max-height: 80vh;
    max-width: 90%;
    z-index: 999;
}

.rating {
    margin: 7px 0;
    width: max-content;
    padding: 7px;
    /* border: 1px solid #ccc;
    background: #f9f9f9; */
}

.rating label {
    float: right;
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.rating label:not(:first-of-type) {
    padding-right: 2px;
}

.rating label:before {
    content: "\2605";
    font-size: 42px;
    color: #ccc;
    line-height: 1;
    text-shadow: 0 0 black;
}

.rating input {
    display: none;
}

.rating input:checked~label:before,
.rating:not(:checked)>label:hover:before,
.rating:not(:checked)>label:hover~label:before {
    color: #f9df4a;
}

.rdw-editor-main .rdw-image-alignment-options-popup,
.rdw-editor-toolbar .rdw-embedded-wrapper {
    display: none;
}

.rdw-editor-main img {
    max-width: 100%;
    max-height: 400px;
}

.rdw-editor-wrapper .rdw-editor-main {
    height: calc(100vh - 300px);
    overflow: auto;
    box-sizing: border-box;
}

.rdw-editor-wrapper .public-DraftEditor-content {
    background-color: white;
    overflow: auto;
    padding: 0 7px;
    border-radius: 2px;
}

.rdw-editor-toolbar {
    margin-bottom: 0;
    background: #2BB801;
    border-radius: 7px 7px 0 0;
    border: none;
}

.editor_block {
    padding: 0;
    min-height: 400px;
    border: 1px solid #008001;
    overflow: hidden;
    border-radius: 7px;
}

.message_preview{
    display: flex;
    max-width: 720px;
    flex-direction: row;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
    background-color: #fff;
    margin-bottom: 15px;
    font-size: 16px;
    color: #353535;
    text-decoration: none;
    width: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.message_preview-text{
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    font-family: arial;
    color: #555;
    width: auto;
  }
  .message_preview .avatar_preview {
    margin: 0 5px 0 0;
  }
  .message_preview .new_message_indicator {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ff0200;
    color: #fff;
    font-weight: 700;
    border-radius: 14px;
}
.message_preview div.message_data{
    width: calc( 100% - 45px );
}
.message_preview small.date_time{
    float: right;
}
.order_card_fixed .message_box{
    width: calc( 100% - 30px );
}
.order_card_fixed .message_box {
    margin-bottom: 0;
    padding: 0 0;
}
.message_block {
    height: calc(100vh - 257px);
}
.text_area {
    margin: 12px 12px;
}

@media (min-width: 1024px) {
.order_card_fixed .message_box {
    margin-bottom: 15px;
    padding: 13px 15px;
}
    .message_block {
        height: calc(100vh - 350px);
    }
    .text_area {
        margin: 12px 0;
    }

}